<template>
    <component :is="layout">
      <slot />
    </component>
  </template>
  
  <script>
  import MicroSiteLayout from '@/layouts/MicroSiteLayout.vue';
  import PortalLayout from '@/layouts/PortalLayout.vue';
  export default {
    name: 'AppLayout',
    components: {
      MicroSiteLayout,
      PortalLayout
    },
    computed: {
      layout() {
        return this.$route.meta?.layout || MicroSiteLayout;
      },
    },
  };
  </script>