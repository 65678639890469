import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./views/PortalView.vue'),
    meta: {
      layout: 'PortalLayout',
    },
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: () => import('./views/TrackingView.vue'),
    meta: {
      layout: 'PortalLayout',
    },
  },
  {
    path: '/payment/:client_refnum',
    name: 'Payment',
    component: () => import('./views/PaymentView.vue'),
    meta: {
      layout: 'PortalLayout',
    },
  },
  {
    path: '/detail-news',
    name: 'DetailNews',
    component: () => import('./views/DetailNews.vue'),
  },
  {
    path: '/receipt/:client_refnum',
    name: 'DownloadReceipt',
    props: route => ({ ...route.params, client_refnum: parseInt(route.params.client_refnum) }),
    component: () => import('./views/ReceiptView.vue'),
  },

  {
    path: '/tracking/:client_refnum',
    name: 'GenerateTracking',
    props: route => ({ ...route.params, client_refnum: parseInt(route.params.client_refnum) }),
    component: () => import('./views/GenerateTracking.vue'),
  },
  {
    path: '/generate',
    name: 'Generate',
    props: route => ({
      pay: route.query.pay,
    }),
    component: () => import('./views/GenerateViewVA.vue'),
  },
  {
    path: '/payment-link/:id',
    name: 'PaymentLink',
    component: () => import('./views/GenerateView.vue'),
  },
  {
    path: '/history/:id',
    name: 'History',
    component: () => import('./views/HistoryView.vue'),
  },
  {
    path: '/error/:message',
    name: 'Error',
    component: () => import('./views/ErrorView.vue'),
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from, next) => {
  // You have access to the route here so you could dynamically get the variable? to.params? (Sorry for editing this post as didn't see the full question!)

  // document.title = to.meta.title ? to.meta.title : "eAZE"

  // Edit 2 it seems params contains the thing you need so you could detect if to.meta.title is a thing and dynamically change it `Profile - (${to.params.userId})`
  next() // You must call next!
})

export default router
