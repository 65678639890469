<template>
    <div class="rounded-[44px] bg-[#EEEEEE] py-2 items-center justify-center hidden sm:block">
        <router-link :to="{name: tab.link}" v-for="tab in switchTabActive" :key="tab.id"
            :class="{
            'w-[170px] h-full px-4 py-3 items-center justify-center': true,
            'bg-white text-eaze-blue font-bold rounded-[44px] transition-all delay-75 shadow-lg': this.selectedBar === tab.link,
            'text-[#757575]': this.selectedBar !== tab.id
            }"
            @click="switchTab(tab.link)"
        >
            {{ tab.title }}
        </router-link>
    </div>
</template>

<script>
import { switchTabActive } from '@/static/dataLayout'
export default {
    name:'SwitchTab',
    data(){
        return {
            switchTabActive,
            selectedBar: switchTabActive[0].link,
        }
    },
    methods: {
        switchTab(payload) {
            this.selectedBar = payload
            this.$router.push({name:payload})
        }
    },
    mounted(){
        this.switchTab(this.$route.name)
    }
}
</script>