<template>
    <nav class="bg-white fixed top-0 left-0 right-0 py-3 z-10 border-b px-8">
        <div class="flex flex-row justify-between px-2 lg:px-28 md:px-28 items-center">
            <BaseLogo />
            <SwitchTab />
            <div></div>
            <IconBadge class="hidden" badge="true">
                <BellIcon class="h-6 w-6" aria-hidden="true" />
            </IconBadge>
            <IconBadge class="block md:hidden lg:hidden" badge="false" @click="isOpenMenu = !isOpenMenu">
                <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </IconBadge>
        </div>
        <div v-if="isOpenMenu"
            class="z-10 block md:hidden lg:hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 absolute right-1 border border-gray-200">
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                <li v-for="tab in switchTabActive" :key="tab.id" @click="isOpenMenu = !isOpenMenu">
                    <router-link :to="{ name: tab.link }"
                        class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                        {{ tab.title }}
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script setup>
import { ref } from 'vue'
import { switchTabActive } from '@/static/dataLayout'
import IconBadge from '@/components/common/IconBadge.vue'
import SwitchTab from '@/components/SwitchTab.vue'
import { BellIcon, Bars3Icon } from '@heroicons/vue/24/outline'

const isOpenMenu = ref(false)
</script>