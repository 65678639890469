import { postRequest, getRequest } from "./http-client";
import { formPayment, isLoggedIn } from "@/store/generalStore"

async function loginToSystem() {
    try {
        const data = {
            access_key: process.env.ACCESS_KEY
        };
        const headers = {
            auth: {
                username: process.env.API_USERNAME,
                password: process.env.API_PASSWORD,
            }
        }
        const response = await postRequest("api/access/platform", data, headers);
        const responseData = response.data;
        localStorage.setItem("TokenCode", `Bearer ${responseData.data.token}`);
        return responseData
    } catch (e) {
        isLoggedIn.value = false
        localStorage.setItem("TokenCode", ``);
        console.log(e)
    }
}

function renderCurrency(data) {
    const priceFormat = new Intl.NumberFormat("en-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
    });
    const listReplaceString = ["IDR", "idr", "RP", "Rp", "rP", "RP.", "Rp.", "rP."]
    const formattedCurrency = priceFormat.format(data)
    let includedString = ''
    for (let index = 0; index < listReplaceString.length; index++) {
        if (formattedCurrency.includes(listReplaceString[index]))
            includedString = listReplaceString[index]
    }
    if (includedString != '') {
        return priceFormat.format(data).replace(includedString, "").trim();
    }
    return priceFormat.format(data).replace("IDR", "").trim();
}

async function getPaymentCategory() {
    const response = await getRequest("api/v1/payment/category")
    if (response.status == 200)
        return response.data
    return {}
}

function validateObject({ objectValidated, validationRules }) {
    let listKey = Object.keys(validationRules);
    for (let index = 0; index < listKey.length; index++) {
        if (validationRules[listKey[index]]['required']) {
            if (objectValidated[listKey[index]] == "" && validationRules[listKey[index]]['required'] == true) {
                return {
                    isValidated: false,
                    message: validationRules[listKey[index]]['messages']['required']
                }
            }
        }
        if (validationRules[listKey[index]]['notZero']) {
            if (parseInt(objectValidated[listKey[index]]) < 1 && validationRules[listKey[index]]['notZero'] == true) {
                return {
                    isValidated: false,
                    message: validationRules[listKey[index]]['messages']['notZero']
                }
            }
        }
    }
    return {
        isValidated: true,
        message: "Form successfully validated"
    }
}
function isLetter(e) {
    let char = String.fromCharCode(e.keyCode); // Get the character
    if (/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
    else e.preventDefault(); // If not match, don't add to input text
}
function isNumber(e) {
    const eventType = e.type
    if (eventType == 'keypress') {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if (/^[0-9]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
    }
    if (eventType == 'paste') {
        let clipboardData = e.clipboardData || window.clipboardData;
        let char = clipboardData.getData('Text');
        if (/^[0-9]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
    }
}

function isFirstNotZero(e) {
    const eventType = e.type
    const keyCodeNumber = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
    let char = String.fromCharCode(e.keyCode); // Get the character
    if (eventType == 'keypress') {
        if (e.target.value.length > 0)
            char = e.target.value
        if (/^(?!(0))[0-9+,]+$/.test(char) && keyCodeNumber.includes(e.keyCode)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
    }
    if (eventType == 'paste') {
        let clipboardData = e.clipboardData || window.clipboardData;
        let char = clipboardData.getData('Text');
        if (/^(?!(0))[0-9+,]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
    }
}

function isLetterAndNumber(e) {
    const eventType = e.type
    if (eventType == 'keypress') {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if (/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
    }
    if (eventType == 'paste') {
        let clipboardData = e.clipboardData || window.clipboardData;
        let char = clipboardData.getData('Text');
        if (/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
    }
}

function resetFormPayment() {
    formPayment.customer_name = ""
    formPayment.id_billing = ""
    formPayment.payment_category = ""
    formPayment.trx_amount = 0
    formPayment.payment_type = ""
}

function parseGuideContent(content, vaNumber = "") {
    return content.replaceAll("[va]", `<b>${vaNumber}</b>`)
}

export {
    loginToSystem,
    renderCurrency,
    getPaymentCategory,
    validateObject,
    isLetter,
    isNumber,
    isLetterAndNumber,
    resetFormPayment,
    isFirstNotZero,
    parseGuideContent
}