<template>
  <div class="bg-eaze-blue rounded-full h-10 w-auto px-5 item-center justify-center">
    {{ title }}
  </div>
</template>

<script>
export default {
  props:{
    title:String,
    type:String
  }
}
 
</script>