<template>
  <div v-if="isLoading" class="animate-pulse w-full bg-gray-100 h-12 rounded-xl"></div>
  <select
    v-else
    class="p-4 border-2 border-gray-300 text-gray-900 rounded-lg w-full dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
    @change="setValueSelected"
    v-model="selected"
  >
    <option disabled selected value="">{{ label }}</option>
    <option
      v-for="(type, index) in listOptions"
      :key="index"
      :value="`${type[valueKey]}`"
    >
     {{ type[titleKey] }}
    </option>
  </select>
</template>
<script>
import { theme } from '../../../tailwind.config'
export default {
  props: {
    isLoading:Boolean,
    label:String,
    listOptions: {
      type: Array,
      defaut: [],
    },
    valueKey:{
      type : String,
      default : 'code'
    },
    titleKey:{
      type : String,
      default : 'name'
    },
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
  },
  data:function(){
    return{
      selected : '',
      eazeBlue: theme.colors['eaze-blue']
    }
  },
  watch:{
    modelValue(newValue){
      this.selected = newValue
    }
  },
  methods:{
    setValueSelected(){
      this.$emit('update:modelValue', this.selected)
    }
  }
};
</script>
<style scoped>
select:focus{
  outline: none;
  box-shadow: none;
  border: 2px solid v-bind(eazeBlue);
}
</style>