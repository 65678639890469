// tailwind.config.js
const defaultTheme = require('tailwindcss/defaultTheme')
module.exports = {
  purge: [
    "./index.html",
    "./src/**/*.{vue,js,ts,jsx,tsx}",
  ],
  safelist: [
    'list-none',
    'list-disc',
    'list-decimal',
  ],
  darkMode: true, // or 'media' or 'class'
  theme: {
    colors: {
      'eaze-blue': "var(--primary-color-core)",
      'button': "var(--text-btn-color)",
    },
    screens: {
      'xs': '360px',
      ...defaultTheme.screens,
    },
    extend: {
      fontFamily: {
        'titillium': ['Titillium Web', 'sans-serif']
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('flowbite/plugin')
  ],
  content: [
    "./node_modules/flowbite/**/*.js"
  ]
}