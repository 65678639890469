import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.API_URL || 'http://127.0.0.1:8000/'
});

async function getRequest(url) {
    instance.defaults.headers.common['Authorization'] = localStorage.getItem('TokenCode');
    return await instance.get(url)
}

async function postRequest(url, data, headers = {}) {
    instance.defaults.headers.common['Authorization'] = localStorage.getItem('TokenCode');
    if (headers != {}) {
        return await instance.post(url, data, headers)
    }
    return await instance.post(url, data)
}

export {
    getRequest,
    postRequest
}