export const switchTabActive= [
    {
        id:"payment",
        title:"Pembayaran",
        link:"Home"
    },
    {
        id:"tracking",
        title:"Tracking",
        link:"Tracking"
    }
]

export const groupPaymentType = [
    {
        id:"1",
        name:"eWallet"
    },
    {
        id:"2",
        name:"PBB"
    },
    {
        id:"3",
        name:"BPHTB"
    },
    {
        id:"4",
        name:"9Pajak"
    },
    {
        id:"5",
        name:"PDAM"
    },
    {
        id:"6",
        name:"BPJS"
    },
    {
        id:"7",
        name:"Voucher"
    },
]