import { ref, reactive } from 'vue'
//payment method 2 = VA, 1 = QRIS
const selectedTabIndex = ref('')
const selectedPaymentType = ref('')
const isLoggedIn  = ref(false)
const isLoadedDetail = ref(false)
const baseLogo = ref(require('@/assets/eazelogo.png'))
const baseLogoMobile = ref(require('@/assets/logo-icon.svg'))
const showEazeLogo = ref(false)
const denomLoaderState = ref(false)
const formPayment = reactive({
    id_billing: "",
    payment_type: "",
    payment_category: "",
    payment_method: "2",
    trx_amount: "",
    customer_name: "",
    description: "generate payment VA",
    callback_url: "http://test-callback.com/",
    platform_type: "PORTAL",
    voucher_id : null
})
const isTimerStop = ref(false)
export {
    selectedTabIndex,
    selectedPaymentType,
    formPayment,
    isTimerStop,
    isLoggedIn, 
    isLoadedDetail,
    baseLogo,
    showEazeLogo,
    baseLogoMobile,
    denomLoaderState
}