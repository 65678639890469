import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import Toast, { POSITION } from 'vue-toastification';
import "vue-toastification/dist/index.css";
import router from './router.js'
import 'sweetalert2/dist/sweetalert2.min.css';

import BaseContainer from './components/common/BaseContainer.vue';
import BaseLogo from './components/common/BaseLogo.vue'
import FlexRow from './components/common/FlexRow.vue'
import FlexCol from './components/common/FlexRow.vue'
import VButton from './components/common/VButton.vue'
import VCardShadow from './components/common/VCardShadow.vue'
import VSelect from './components/common/VSelect.vue'
import './registerServiceWorker'

createApp(App)
    .use(router)
    .use(Toast, {
        position: POSITION.TOP_RIGHT
    })
    .component("BaseContainer", BaseContainer)
    .component("BaseLogo", BaseLogo)
    .component("FlexRow", FlexRow)
    .component("FlexCol", FlexCol)
    .component("Button", VButton)
    .component("Card", VCardShadow)
    .component("Select", VSelect)
    .mount('#app')

